/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
import { loginReducer } from '../components/login/redux/reducer';
import {
  forgotPasswordEpic,
  setNewPasswordEpic,
  signInEpic,
  signUpConfirmCodeEpic,
  signUpEpic,
  signOutEpic,
} from '../components/login/redux/epics';
const rootEpic = combineEpics(
  signUpEpic,
  signUpConfirmCodeEpic,
  signInEpic,
  forgotPasswordEpic,
  setNewPasswordEpic,
  signOutEpic,
);

const rootReducer = combineReducers({
  loginReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
