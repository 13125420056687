/*External dependencies*/
import update from 'immutability-helper';
/* Local dependencies */
import { removeItemFromLocalStorage, setLocalStorage } from '../../../utils/helpers';
import { mockUsers } from '../mockData';
import { LoginAction, LoginActionTypes, LoginSteps } from './action';
export interface User {
  id?: string;
  phoneNumber?: string;
  points?: string;
  profilePhoto?: {};
  role?: string;
  userName?: string;
}

export interface LoginState {
  user: User;
  username?: string;
  password?: string;
  repeatPassword?: string;
  error?: string;
  loginStep?: LoginSteps;
  loading?: boolean;
  isOpen?: boolean;
  confirmCode?: string;
  newPassword?: string;
  isLoggedIn?: boolean;
}

export const initialLoginState: LoginState = {
  user: {},
  error: null,
  loginStep: LoginSteps.INITIAL_FORM,
};

export function loginReducer(state = initialLoginState, action: LoginAction) {
  const emailRegEx = /^\S+@\S+\.\S+$/;
  const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  switch (action.type) {
    case LoginActionTypes.SIGNUP_EMAIL_REQUEST:
      if (action.username.match(emailRegEx)) {
        return update(state, {
          $unset: ['error'],
          loginStep: { $set: LoginSteps.REGISTRATION_PASSWORD_FORM },
          username: { $set: action.username },
        });
      }
      return update(state, {
        // error: { $set: action.error },
      });

    case LoginActionTypes.SIGNUP_PASSWORD_REQUEST:
      if (action.password == action.passwordRepeat) {
        return update(state, {
          $unset: ['error'],
          password: { $set: action.password },
          repeatPassword: { $set: action.passwordRepeat },
          loginStep: { $set: LoginSteps.REGISTRATION_CODE_FORM },
        });
      }

      return update(state, {
        loginStep: { $set: LoginSteps.REGISTRATION_PASSWORD_FORM },
      });

    case LoginActionTypes.SIGNUP_SUCCEEDED:
      return update(state, {
        loginStep: { $set: LoginSteps.LOGIN_FORM },
        loading: { $set: false },
      });

    case LoginActionTypes.SIGNUP_FAILED:
      return update(state, {
        error: { $set: action.error.name },
        loginStep: { $set: LoginSteps.REGISTRATION_PASSWORD_FORM },
      });

    case LoginActionTypes.SIGNUP_CONFIRM_CODE:
      if (action.confirmCode) {
        return update(state, {
          $unset: ['error'],
          loading: { $set: true },
        });
      }

      return update(state, {});

    case LoginActionTypes.SIGNUP_CONFIRM_CODE_FAILED:
      return update(state, {
        error: { $set: action.error.name },
        loginStep: { $set: LoginSteps.REGISTRATION_CODE_FORM },
        loading: { $set: false },
      });

    case LoginActionTypes.SIGNIN_REQUEST:
      const userSignin = mockUsers.find((user) => user.email === action.username);

      if (userSignin) {
        setLocalStorage('authToken', userSignin.email);
        setLocalStorage('points', userSignin.points);
        return update(state, {
          $unset: ['error'],
          loading: { $set: true },
        });
      }

      return update(state, {});

    case LoginActionTypes.SIGNIN_SUCCEEDED:
      return update(state, {
        $unset: ['error'],
        isOpen: { $set: false },
        isLoggedIn: { $set: true },
      });

    case LoginActionTypes.SIGNIN_FAILED:
      return update(state, {
        loading: { $set: false },
        loginStep: { $set: LoginSteps.LOGIN_FORM },
        error: { $set: action.error.name },
      });

    case LoginActionTypes.FORGOT_PASSWORD_REQUEST:
      if (action.username.match(emailRegEx)) {
        return update(state, {
          $unset: ['error'],
          username: { $set: action.username },
          loginStep: { $set: LoginSteps.SEND_CODE_FOR_NEW_PASSWORD_MODAL },
        });
      }

      return update(state, {});

    case LoginActionTypes.FORGOT_PASSWORD_FAILED:
      return update(state, {
        error: { $set: action.error.name },
        loginStep: { $set: LoginSteps.FORGOT_PASSWORD_MODAL },
      });

    case LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_REQUEST:
      return update(state, {
        confirmCode: { $set: action.confirmCode },
        loginStep: { $set: LoginSteps.SET_NEW_PASSWORD_FORM },
      });

    case LoginActionTypes.SET_NEW_PASSWORD_REQUEST:
      const userNewPassword = mockUsers.find((mockUser) => mockUser.email === action.username);

      if (action.newPassword.match(passwordRegEx) && userNewPassword) {
        setLocalStorage('authToken', userNewPassword.email);
        setLocalStorage('points', userNewPassword.points);
        return update(state, {
          $unset: ['error'],
          newPassword: { $set: action.newPassword },
          loading: { $set: true },
        });
      }

      return update(state, {});

    case LoginActionTypes.SET_NEW_PASSWORD_SUCCEEDED:
      return update(state, {
        isLoggedIn: { $set: true },
        isOpen: { $set: false },
      });

    case LoginActionTypes.SIGN_OUT_REQUEST:
      removeItemFromLocalStorage('authToken');
      removeItemFromLocalStorage('points');
      return update(state, {
        loginStep: { $set: LoginSteps.INITIAL_FORM },
      });

    case LoginActionTypes.SET_STEP:
      return update(state, {
        loginStep: { $set: action.step },
      });
    // login steps
    case LoginActionTypes.LOGIN_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.LOGIN_FORM },
      });

    case LoginActionTypes.INITIAL_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.INITIAL_FORM },
      });

    case LoginActionTypes.EMAIL_REGISTRATION_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.REGISTRATION_EMAIL_FORM },
      });

    case LoginActionTypes.CODE_REGISTRATION_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.REGISTRATION_CODE_FORM },
      });

    case LoginActionTypes.SEND_CODE_FOR_NEW_PASSWORD_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.SEND_CODE_FOR_NEW_PASSWORD_MODAL },
      });

    case LoginActionTypes.OPEN_MODAL:
      return update(state, {
        isOpen: { $set: true },
      });

    case LoginActionTypes.CLOSE_MODAL:
      return update(state, {
        isOpen: { $set: false },
      });
    case LoginActionTypes.FORGOT_PASSWORD_FORM:
      return update(state, {
        loginStep: { $set: LoginSteps.FORGOT_PASSWORD_MODAL },
      });

    default:
      return state;
  }
}

// const change = {
//   error: { $set: null },
// };

// if (!action.username) {
//   change.error = {
//     $set: 'Wrong email format',
//   };
// } else {
//   change['$unset'] = ['error'];
// }

// if (action.username && action.username?.match(emailRegEx)) {
//   return update(state, {
//     ...change,
//     loginStep: { $set: LoginSteps.REGISTRATION_PASSWORD_FORM },
//     username: { $set: action.username },
//   });
// } else {
//   return update(state, {
//     ...change,
//     username: { $set: action.username },
//   });
// }
