exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-acquiring-tsx": () => import("./../../../src/pages/acquiring.tsx" /* webpackChunkName: "component---src-pages-acquiring-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../../../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-terminals-tsx": () => import("./../../../src/pages/terminals.tsx" /* webpackChunkName: "component---src-pages-terminals-tsx" */),
  "component---src-pages-wallet-tsx": () => import("./../../../src/pages/wallet.tsx" /* webpackChunkName: "component---src-pages-wallet-tsx" */),
  "component---src-pages-web-app-tsx": () => import("./../../../src/pages/webApp.tsx" /* webpackChunkName: "component---src-pages-web-app-tsx" */)
}

